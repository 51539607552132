<template>
  <div id="container">
    <h2>{{$gettext('Registrazione utente')}}</h2>
    <div id="signup">
      <form @submit.prevent="signup">
        <div id="username_div">
          <custom-input
            :placeholder='$gettext("Nome utente *")'
            label="username"
            :value="username"
            type="text"
            @input="(val) => (username = val)"
          />
          <div
            v-if="usernameErr != ''"
            id="password_error"
            class="error"
            v-html="usernameErr"
          ></div>
        </div>
        <div id="password_div">
          <custom-input
            placeholder="Password *"
            label="password"
            :value="password"
            type="password"
            @input="(val) => (password = val)"
          />
          <div v-if="passwordErr != ''" id="password_error" class="error">
            {{ passwordErr }}
          </div>
        </div>
        <div id="password2_div">
          <custom-input
            :placeholder='$gettext("Conferma password *")'
            label="password2"
            :value="password2"
            type="password"
            @input="(val) => (password2 = val)"
          />
          <div v-if="passwordErr != ''" id="password2_error" class="error">
            {{ passwordErr }}
          </div>
        </div>
        <div id="email_div">
          <custom-input
            :placeholder='$gettext("Indirizzo email *")'
            label="email"
            :value="email"
            type="text"
            @input="(val) => (email = val)"
          />
          <div id="email_error" class="error" v-html="emailErr"></div>
        </div>
        <div id="privacyDiv">
          <input type="checkbox" id="privacy" value="" v-model="privacy">
          <label for="privacy">{{ $gettext('Iscrivendoti e creando un account, dichiari di essere maggiorenne ed aver preso visione e accettato integralmente i ') }}<a href="/page/149">{{ $gettext('Termini e le Condizioni') }}</a>{{ $gettext('e la ') }}<a href="/page/3">Privacy policy</a> {{ $gettext('di Sirman. *') }} </label>
        </div>
        <div id="buttons">
          <button :disabled="loading">{{ $gettext('Registrati') }}</button>
        </div>
        <div id="loading" v-if="loading">
          {{ $gettext('Registrazione in corso. Attendere.') }}
        </div>
        <div id="obbligo">{{ $gettext('* Tutti i campi sono obbligatori ai fini della registrazione') }}</div>
        <div
          id="genericError"
          v-if="genericError != ''"
          v-html="genericError"
        ></div>
      </form>
    </div>
    <hr />
    <h2>{{ $gettext('Sei già registrato ?') }}</h2>
    <button @click="login">{{ $gettext('Effettua il login') }}</button>
  </div>
</template>

<script>
import customInput from '@/components/shared/custom-input';

export default {
  name: 'signup.vue',
  components: { customInput },
  data() {
    return {
      username: '',
      usernameErr: '',
      email: '',
      emailErr: '',
      password: '',
      password2: '',
      passwordErr: '',
      loading: false,
      genericError: '',
      privacy: false
    };
  },
  methods: {
    signup() {
      this.username = this.username.trim();
      this.email = this.email.trim();
      this.password = this.password.trim();
      this.password2 = this.password2.trim();

      this.usernameErr = '';
      this.passwordErr = '';
      this.emailErr = '';

      var err = false;
      const usernameErrArr = [];
      if (!this.fillOk) return;

      if (this.username.length < 8) {
        err = true;
        usernameErrArr.push($gettext('Il campo deve essere lungo almeno 8 caratteri'));
      }

      let regex = new RegExp(/[^a-zA-Z0-9]/gi);
      const usernameCheck = this.username.replace(regex, '');
      if (this.username != usernameCheck) {
        err = true;
        usernameErrArr.push($gettext('Deve contenere solo lettere e numeri.'));
      }
      this.usernameErr = usernameErrArr.join('<br>\n');

      // Controllo che le due password siano identiche
      if (this.password != this.password2) {
        this.passwordErr = $gettext('Le due password devono essere identiche');
        err = true;
      } else if (this.password.length < 8) {
        this.passwordErr = gettext('Le due password devono essere lunghe almeno 8 caratteri');
        err = true;
      }

      if (err) return;
      this.loading = true;
      this.axios
        .post(this.api.signup, {
          username: this.username,
          password: this.password,
          email: this.email,
          privacy: this.privacy
        })
        .then((response) => {
          this.loading = false;
          this.genericError = response.message;
          this.$router.push('/signup_code');
          return;
        })
        .catch((response) => {
          this.loading = false;
          if (response.message.includes('Username')) {
            this.usernameErr =
              'Nome utente già in uso. <a href="#">Hai dimenticato la password?</a>';
          } else if (response.response.data.message.includes('Email')) {
            this.emailErr =
              'Email già in uso. <a href="#">Hai dimenticato la password?</a>';
          }
          //this.genericError = response.response.data.message;
        });
    },
    login() {
      this.$router.push('/login');
    },
  },
  computed: {
    fillOk() {
      if (
        this.email.trim() != '' &&
        this.username.trim() != '' &&
        this.password2.trim() != '' &&
        this.password.trim() != '' &&
        this.privacy == true
      )
        return true;
      return false;
    },
  },
};
</script>

<style scoped>
.error {
  margin-top: 3px;
  text-align: left;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-size: 80%;
  color: var(--var-primary-color);
}

button {
  margin-top: 10px;
}

#genericError {
  font-size: 80%;
  font-weight: 400;
  color: var(--var-primary-color);
}

#genericError a {
  color: var(--var-primary-color);
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
}

#container {
  padding: 10px;
}

#privacyDiv {
  margin-top: 10px;
}

#obbligo {
  font-size: 10px;
  font-style: italic;
  margin-top: 5px;
}
</style>
